import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.2322 12.5962L11.0503 9.4142L10.9563 9.31631C10.763 9.11481 10.7721 8.79398 10.9767 8.60387L11.0502 8.53553L14.2322 5.35355C14.4275 5.15829 14.7441 5.15829 14.9393 5.35355L15.6464 6.06066C15.8417 6.25592 15.8417 6.57251 15.6464 6.76777L14.4644 7.94974L18 7.94974C20.2091 7.94974 22 9.7406 22 11.9497C22 14.1589 20.2091 15.9497 18 15.9497L16 15.9497C15.4477 15.9497 15 15.502 15 14.9497C15 14.3975 15.4477 13.9497 16 13.9497L18 13.9497C19.1046 13.9497 20 13.0543 20 11.9497C20 10.8452 19.1046 9.94974 18 9.94974H14.4142L15.6464 11.182C15.8417 11.3772 15.8417 11.6938 15.6464 11.8891L14.9393 12.5962C14.7441 12.7914 14.4275 12.7914 14.2322 12.5962Z" />
      <path d="M9.58579 14H6C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10H8C8.55228 10 9 9.55228 9 9C9 8.44771 8.55228 8 8 8H6C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16H9.53555L8.35358 17.182C8.15832 17.3772 8.15832 17.6938 8.35358 17.8891L9.06069 18.5962C9.25595 18.7914 9.57253 18.7914 9.7678 18.5962L12.9498 15.4142L13.0233 15.3459C13.2279 15.1558 13.237 14.8349 13.0437 14.6334L12.9497 14.5355L9.76777 11.3536C9.5725 11.1583 9.25592 11.1583 9.06066 11.3536L8.35355 12.0607C8.15829 12.2559 8.15829 12.5725 8.35355 12.7678L9.58579 14Z" />
    </svg>
  );
};

export default Icon;
