import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CreditCardIcon: FC<SVGIconProps> = ({ className, ...rest }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M6 14H8V16H6V14Z" />
      <path d="M12 14H10V16H12V14Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C1 4.89543 1.89543 4 3 4H21C22.1046 4 23 4.89543 23 6V18C23 19.1046 22.1046 20 21 20H3C1.89543 20 1 19.1046 1 18V6ZM3 6H21V8H3L3 6ZM3 11L3 18H21V11H3Z"
      />
    </svg>
  );
};

export default CreditCardIcon;
