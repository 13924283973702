import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.5004C1 11.2242 1.22386 11.0004 1.5 11.0004L13.4142 11.0004L11.9394 9.5255C11.7441 9.33024 11.7441 9.01366 11.9394 8.8184L12.6465 8.11129C12.8417 7.91603 13.1583 7.91603 13.3536 8.11129L16.8891 11.6468C16.9623 11.72 17.0081 11.8103 17.0264 11.9049C17.0569 12.0625 17.0112 12.2319 16.8891 12.3539L13.3536 15.8895C13.1583 16.0847 12.8417 16.0847 12.6465 15.8895L11.9394 15.1824C11.7441 14.9871 11.7441 14.6705 11.9394 14.4753L13.4142 13.0004H1.5C1.22386 13.0004 1 12.7765 1 12.5004L1 11.5004ZM21 14.0004C22.1046 14.0004 23 13.1049 23 12.0004C23 10.8958 22.1046 10.0004 21 10.0004C19.8954 10.0004 19 10.8958 19 12.0004C19 13.1049 19.8954 14.0004 21 14.0004Z"
      />
    </svg>
  );
};

export default Icon;
