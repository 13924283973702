import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PathIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2H15C17.2091 2 19 3.79086 19 6C19 8.20914 17.2091 10 15 10H7.5C5.567 10 4 11.567 4 13.5C4 15.433 5.567 17 7.5 17H9.58579L8.35355 15.7678C8.15829 15.5725 8.15829 15.2559 8.35355 15.0607L9.06066 14.3536C9.25592 14.1583 9.5725 14.1583 9.76777 14.3536L12.9497 17.5355L13.0437 17.6334C13.237 17.8349 13.2279 18.1558 13.0233 18.3459L12.9498 18.4142L9.7678 21.5962C9.57253 21.7914 9.25595 21.7914 9.06069 21.5962L8.35358 20.8891C8.15832 20.6938 8.15832 20.3772 8.35358 20.182L9.53555 19H7C4.23858 19 2 16.7614 2 14V13C2 10.2386 4.23858 8 7 8H15C16.1046 8 17 7.10457 17 6C17 4.89543 16.1046 4 15 4H12V2ZM18 19C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17C17.4477 17 17 17.4477 17 18C17 18.5523 17.4477 19 18 19ZM18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
      />
    </svg>
  );
};

export default PathIcon;
