import { round } from "lodash";
import { DateTime } from "luxon";

import { getNormalizedDate } from "@lib/appointments";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageItemsType } from "@lib/data/schemas/packages";
import { countDiffDays, weekdays, WeekdayType } from "@lib/packages";
import { AppointmentType } from "@lib/shared-types";

/**
 * Returns a rounded number from invoice items
 * */
type PackageItemsWithUnits = PackageItemsType & {
  unitAmount: number;
  itemAmount: number;
};
export const getInvoiceItems = (items: PackageItemsWithUnits[]) => {
  const invoiceItems = items?.map((item) => {
    const items = [];
    const pseudoTotal = item.quantity * item.unitAmount;

    const difference = round(item.itemAmount - pseudoTotal, 2);
    if (difference === 0) {
      items.push(item);
    } else {
      if (item.quantity !== 1)
        items.push({
          ...item,
          quantity: item.quantity - 1,
          itemAmount: (item.quantity - 1) * item.unitAmount,
        });

      const remaining = round(item.unitAmount + difference, 0);

      items.push({
        ...item,
        quantity: 1,
        unitAmount: remaining,
        itemAmount: remaining,
      });
    }

    return items;
  });
  return invoiceItems.flat();
};

/**
 * Get cycle dates based on start date, reset date, frequency and cycle
 * */
export const getCycleDates = (
  startDate: Date,
  resetDate: Date,
  frequency: string,
  cycle: number,
  latestCycle: number
) => {
  const localStartDate = DateTime.fromJSDate(startDate);
  const localResetDate = DateTime.fromJSDate(resetDate);

  // We're using the reset date as the base date to calculate the cycle
  // because we allow coaches to update the reset date from an option
  // to update the next cycle start date.
  // Today we don't store the dates by cycle, what makes this not accurate
  // by cycle, but this is the closest we can get without changing the
  // database structure.
  const startDateCycle =
    cycle === 1
      ? localStartDate
      : localResetDate.minus({
          [frequency]: latestCycle - (cycle - 1),
        });
  const frequencyType = frequency;

  const endDateCycle = startDateCycle
    .plus({
      [frequencyType]: 1,
    })
    .minus({ days: cycle === latestCycle ? 0 : 1 });

  return {
    startDate: startDateCycle.startOf("day").toJSDate(),
    endDate: endDateCycle.endOf("day").toJSDate(),
  };
};

/**
 * Calculates cycle dates for monthly UBP
 * */
const getMonthlyCycleDates = (
  packageInstance: PackageInstanceType,
  startDate: DateTime,
  cycle: number
) => {
  const isFirstCycle = cycle === 1;
  const isLastOfTheMonth =
    packageInstance?.usageInvoicing?.cutOffDate === "last-of-month";
  const sumDaysFromMonthly = isLastOfTheMonth ? 0 : 1;

  // start date
  const nextStartDate = startDate.plus({ months: cycle - 1 });
  const nextStartDateCycle = nextStartDate
    .startOf("month")
    .plus({ days: sumDaysFromMonthly });
  const start = isFirstCycle ? startDate : nextStartDateCycle;

  // end date
  const endDateFromFirstCycle = start
    .endOf("month")
    .plus({ days: sumDaysFromMonthly });
  const nextEndDate = start.plus({ month: 1 });
  const end = isFirstCycle
    ? endDateFromFirstCycle
    : nextEndDate.minus({ days: 1 });

  return {
    start,
    end,
  };
};

type CycleDateTimeType = {
  cycle: number;
  start: DateTime;
  end: DateTime;
};

/**
 * Calculates cycle dates for weekly UBP
 * */
const getWeeklyCycleDates = (
  packageInstance: PackageInstanceType,
  startDate: DateTime,
  cycle: number,
  previousCycle: CycleDateTimeType | null
) => {
  const isFirstCycle = cycle === 1;
  const cutOffDate = packageInstance?.usageInvoicing?.cutOffDate ?? "monday";
  const startDay = weekdays[startDate.weekday - 1];
  const daysUntilCutOff = countDiffDays(
    startDay as WeekdayType,
    cutOffDate as WeekdayType
  );

  const start = previousCycle?.end?.plus({ days: 1 }) ?? startDate;
  const end = isFirstCycle
    ? start.plus({ days: daysUntilCutOff })
    : start.plus({ days: 6 });

  return {
    start,
    end,
  };
};

/**
 * Get all cycle dates from a usage based package instance
 * */
export const getAllCycleDatesFromUsageBasedPackage = (
  packageInstance: PackageInstanceType,
  targetDate?: Date
) => {
  const baseDate = targetDate
    ? DateTime.fromJSDate(targetDate)
    : DateTime.now();
  const startDate = DateTime.fromJSDate(
    getNormalizedDate(packageInstance?.startDate)
  );
  const frequencyType = packageInstance?.frequency?.type ?? "months";
  const isMonthly = frequencyType === "months";
  const frequency = isMonthly ? "month" : "week";
  const frequencyDiff =
    baseDate.diff(startDate, frequency).toObject()[frequencyType] ?? 0;
  const totalCyclesDiff = frequencyDiff + 1;

  const totalCycles = Math.ceil(totalCyclesDiff);
  const arrayCycles = Array.from({ length: totalCycles }, (_, i) => i + 1);

  let previousCycle: CycleDateTimeType | null = null;

  const allCycles = arrayCycles.map((cycle) => {
    const { start, end } = isMonthly
      ? getMonthlyCycleDates(packageInstance, startDate, cycle)
      : getWeeklyCycleDates(packageInstance, startDate, cycle, previousCycle);

    const cycleData = {
      cycle,
      start,
      end,
    };

    previousCycle = cycleData;

    return cycleData;
  });

  const formattedAllCycles = allCycles.map((item) => ({
    cycle: item.cycle,
    startDate: item.start.startOf("day").toJSDate(),
    endDate: item.end.endOf("day").toJSDate(),
  }));

  return formattedAllCycles ?? [];
};

/**
 * Get the furthest appointment date from a list of appointments.
 * It's used mainly to calculate the total cycles in a package instance,
 * specially when there are appointments in the future.
 * */
export const getFurthestAppointmentDate = (
  appointments: AppointmentType[] = []
) => {
  if (!appointments || !appointments.length) return null;
  const updatedAppts = appointments.map((appt) => ({
    start: getNormalizedDate(appt.start),
  }));
  return updatedAppts.reduce((prev, current) =>
    prev.start > current.start ? prev : current
  ).start;
};

/**
 * Get all cycle dates from a package instance
 * */
export const getAllCycleDates = (
  packageInstance: PackageInstanceType,
  appointments?: AppointmentType[]
) => {
  const {
    startDate,
    packageType,
    currentCycle,
    resetDate: packageInstanceResetDate,
  } = packageInstance;
  const isUsageBased = packageType === "usage";
  const frequency = isCycleBased(packageType)
    ? isUsageBased
      ? { type: "months" }
      : packageInstance.frequency
    : null;
  // the reset date doesn't matter for usage based packages anymore, but we
  // still need to set any date to let the component work for now
  const resetDate = isUsageBased ? startDate : packageInstanceResetDate;

  if (
    !isCycleBased(packageType) ||
    !startDate ||
    !currentCycle ||
    !frequency ||
    !resetDate
  )
    return null;

  if (isUsageBased) {
    const furthestAppointmentDate = getFurthestAppointmentDate(appointments);
    const isFurthestApptDateHigherThanToday =
      DateTime.fromJSDate(furthestAppointmentDate).diff(DateTime.now(), "days")
        .days > 0;
    return getAllCycleDatesFromUsageBasedPackage(
      packageInstance,
      isFurthestApptDateHigherThanToday ? furthestAppointmentDate : undefined
    );
  }

  const arrayCycles = Array.from({ length: currentCycle }, (_, i) => i + 1);

  const allCycles = arrayCycles.map((cycle) => {
    const { startDate: cycleStartDate, endDate: cycleEndDate } = getCycleDates(
      startDate,
      resetDate,
      frequency.type,
      cycle,
      currentCycle
    );
    return {
      startDate: cycleStartDate,
      endDate: cycleEndDate,
      cycle,
    };
  });

  // make endDate adjustments from first cycle
  const updatedCycles = allCycles.map((cycle) => {
    const nextCycle = allCycles.find((c) => c.cycle === cycle.cycle + 1);
    return {
      ...cycle,
      endDate:
        cycle.cycle === 1 && !!nextCycle
          ? DateTime.fromJSDate(nextCycle.startDate)
              .minus({ days: 1 })
              .endOf("day")
              .toJSDate()
          : cycle.endDate,
    };
  });

  return updatedCycles;
};

/**
 * Gets the current cycle based on start date and current date
 * */
export const getCurrentCycle = (packageInstance?: PackageInstanceType) => {
  if (!packageInstance) return null;
  const { startDate, packageType } = packageInstance;

  if (!isCycleBased(packageType) || !startDate) return null;

  const allCycleDates = getAllCycleDates(packageInstance);
  if (!allCycleDates) return null;

  // find the current cycle based on the current date
  const todaysCycle = allCycleDates.find(({ startDate, endDate }) => {
    const start = DateTime.fromJSDate(startDate);
    const end = DateTime.fromJSDate(endDate);
    return DateTime.now() >= start && DateTime.now() <= end;
  });

  return todaysCycle || null;
};

/**
 * Calculates the initial month to display in the booking calendar
 * based on a date iso string. If it's not set, it defaults to 0.
 * */
export const calculateInitialBookMonth = (startDate?: string) => {
  if (!startDate) return 0;

  const currentDateToCompare = DateTime.fromISO(startDate);
  const now = DateTime.local();
  if (currentDateToCompare < now) return 0;

  const currentMonth = now.month;
  const packageCycleMonth = currentDateToCompare.month;
  let targetMonth = packageCycleMonth - currentMonth;

  if (currentMonth > packageCycleMonth) {
    targetMonth = 12 - currentMonth + packageCycleMonth;
  }

  return targetMonth < 0 ? 0 : targetMonth;
};

/**
 * Checks if the package instance is cycle based
 * */
export const isCycleBased = (
  packageType?: PackageInstanceType["packageType"]
) => ["recurring", "usage"].includes(packageType ?? "");
