import { Timestamp } from "firebase/firestore";
import { calendar_v3 } from "googleapis";
import { compact, find, some, uniqBy } from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import momentTimezone from "moment-timezone";

import { NotFoundError } from "@lib/api/errors/not-found-error";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { ClientType } from "@lib/data/schemas/client";
import pluralHelper from "@lib/utils/pluralHelper";

import { LimitClientControlAllowedActions } from "./constants/limitClientControls";
import { AccountType } from "./data/schemas/account";
import { SchedulerType } from "./data/schemas/scheduler";
import { displayNameFromContact } from "./contacts";
import {
  AttendeeType,
  FirestoreDocType,
  FirestoreDocumentData,
  GroupType,
} from "./shared-types";
import { getCurrentURIFromServer } from "./utils";

export type AppointmentWithOrganizerType = AppointmentType & {
  organizer?: AccountType;
};

type GetApptsWithOneRecurringType = (
  appointments: AppointmentType[]
) => AppointmentType[];

type GetApptsWithRecurringType = (
  appointments: AppointmentType[]
) => AppointmentType[];

export type GetRelatedAppointmentDataProps = {
  appointmentDoc: FirestoreDocType;
};
export type GetRelatedAppointmentDataType = (
  props: GetRelatedAppointmentDataProps
) => Promise<{
  appointment: FirestoreDocumentData;
  contact?: FirestoreDocumentData;
  contactDoc?: FirestoreDocType;
  coach: FirestoreDocumentData;
  coachDoc: FirestoreDocType;
  scheduler: FirestoreDocumentData;
}>;

export type FormatCountAttendeesTitleType = (
  appointment: AppointmentType,
  clients: ClientType[]
) => string;

export type FormatEventShareTitle = (
  appointment: AppointmentType,
  titleOnly?: boolean,
  dateOnly?: boolean
) => string;

export type MapAttendeeEmail = (attendee: AttendeeType) => string;
export type FilterAttendeeNoCancelled = (attendee: AttendeeType) => boolean;

export const getApptsWithOneRecurring: GetApptsWithOneRecurringType = (
  appointments = []
) => {
  const now = new Date();
  const preparedAppointments = appointments
    .filter(
      (appt) =>
        !appt.repeat &&
        // Hack to filter out some odd, cancelled recurring appointments that are missing recurring data
        !(
          appt.gcal?.event?.created === "0000-12-31T00:00:00.000Z" &&
          appt.gcal?.event?.status === "cancelled"
        )
    )
    .sort((a, b) => a.start.getTime() - b.start.getTime());

  const getDistinctId = (appt: AppointmentType) => {
    const isFuture = appt.start > now;
    return (isFuture && appt.gcal?.event?.recurringEventId) || appt.id;
  };

  const idMap = new Map<string, AppointmentType[]>();
  preparedAppointments.forEach((appt) => {
    const id = getDistinctId(appt);
    const existing = idMap.get(id);
    if (existing) {
      existing.push(appt);
    } else {
      idMap.set(id, [appt]);
    }
  });

  const recurringAppointments = compact(
    Array.from(idMap.values()).map((appts) => {
      return appts.at(0);
    })
  );

  return recurringAppointments;
};

/**
 * Get recurring appointments. We only create future recurring appointments a year in advance.
 * */
export const getRecurringAppointments: GetApptsWithRecurringType = (
  appointments = []
) => {
  const recurringAppointments = appointments
    .filter(
      (appt) =>
        !appt.repeat &&
        // Hack to filter out some odd, cancelled recurring appointments that are missing recurring data
        !(
          appt.gcal?.event?.created === "0000-12-31T00:00:00.000Z" &&
          appt.gcal?.event?.status === "cancelled"
        )
    )
    .sort((a, b) => a.start.getTime() - b.start.getTime());

  return recurringAppointments;
};

/**
 * Get other artifact data related with an appointment doc
 * */
export const getRelatedAppointmentData: GetRelatedAppointmentDataType = async ({
  appointmentDoc,
}) => {
  const appointment = appointmentDoc.data();
  const { contactId, availabilityId, groupId } = appointment;

  // coach
  const coachDocRef = appointmentDoc.ref.parent.parent;
  const coachDoc = await coachDocRef.get();
  const coach = coachDoc.data();

  // scheduler
  let scheduler = {};
  if (availabilityId) {
    const schedulerDocRef = appointmentDoc.ref.parent.parent
      .collection("schedulers")
      .doc(availabilityId);
    const schedulerDoc = await schedulerDocRef.get();
    scheduler = schedulerDoc.data();
  }

  if (groupId || !contactId) {
    return {
      appointment,
      coach,
      coachDoc,
      scheduler,
    };
  }

  if (!contactId) {
    throw new NotFoundError("clients", contactId);
  }

  // client
  const contactDocRef = appointmentDoc.ref.parent.parent
    .collection("clients")
    .doc(contactId);
  const contactDoc = await contactDocRef.get();
  const contact = contactDoc.data();

  return {
    appointment,
    contact,
    contactDoc,
    coach,
    coachDoc,
    scheduler,
  };
};

export const mapAttendesEmail: MapAttendeeEmail = (attendee) => attendee.email;
export const filterAttendesNoCancelled: FilterAttendeeNoCancelled = (
  attendee
) => attendee.responseStatus !== "declined";
export const filterIsEvent = (appointment: AppointmentType): boolean =>
  !!appointment?.eventData;
export const filterIsGroupAppt = (appointment: AppointmentType): boolean =>
  !!appointment?.groupId;
export const filterIsNotEventOrGroup = (
  appointment: AppointmentType
): boolean => !appointment?.eventData && !appointment?.groupId;
export const filterIsNotEvent = (appointment: AppointmentType): boolean =>
  !appointment?.eventData;

export const filterIsNotCancelledRecurring = (appt: AppointmentType): boolean =>
  !appt?.recurring || (appt?.recurring && appt?.status !== "cancelled");

export const mapDeclinedAppointment = (
  appointment: AppointmentType
): AppointmentType => {
  const appointmentStatus = decoratedAppointmentStatus(appointment);

  return {
    ...appointment,
    status: appointmentStatus,
  };
};

export const formatCountAttendeesTitle: FormatCountAttendeesTitleType = (
  appointment,
  clients
) => {
  const attendees = appointment.gcal?.event?.attendees || [];
  const filteredAttendees = attendees.filter(filterAttendesNoCancelled);
  const attendeesEmails = filteredAttendees.map(mapAttendesEmail);

  const uniqContacts = uniqBy<ClientType>(clients, "email");
  const clientAttendees = uniqContacts.filter(
    (item: ClientType) =>
      attendeesEmails.includes(item.email) ||
      (item?.emails &&
        some(item.emails, (otherEmail: string) =>
          attendeesEmails.includes(otherEmail)
        ))
  );
  const total = clientAttendees.length;

  const limit =
    appointment?.eventData?.attendeesLimit === "unlimited"
      ? ""
      : `/${appointment?.eventData?.attendeesLimit}`;
  return `${total}${limit} ${pluralHelper(total, "attendee", false)}`;
};

export const formatEventShareTitle: FormatEventShareTitle = (
  appointment,
  titleOnly = false,
  dateOnly = false
) => {
  if (!appointment) return "";

  const { title, start } = appointment;
  const date = moment(getNormalizedDate(start)).format("ddd, MMM DD, h:mm A");

  if (titleOnly) return title;
  if (dateOnly) return date;

  return `${title} on ${date}`;
};

// helpers to format google event description
export const getAppointmentRescheduleDescription = (
  coachId: string,
  appointmentId: string
): string => {
  const url = `${getCurrentURIFromServer()}/users/${coachId}/appointments/${appointmentId}`;
  return `Click here to view appointment: <a href="${url}">${url}</a>\n\n`;
};

export const getAppointmentClientRecordDescription = (
  clientId: string
): string => {
  const url = `${getCurrentURIFromServer()}/contacts/${clientId}`;
  return `Client record (Admins only): <a href="${url}">${url}</a>\n\n`;
};

export const isEventDeclined = (event: calendar_v3.Schema$Event): boolean => {
  if (event?.attendees) {
    const selfAttendee = find(event?.attendees, { self: true });
    if (selfAttendee && selfAttendee.responseStatus === "declined") {
      return true;
    }
  }
  return false;
};

export const isNonBlockingEvent = (
  event: calendar_v3.Schema$Event
): boolean => {
  return (
    isEventDeclined(event) ||
    event?.status === "declined" ||
    event?.transparency === "transparent"
  );
};

export const checkApptTypeAndWhen = (
  appt: AppointmentType,
  past = true
): boolean => {
  if (!appt.start) return false;
  const now = Timestamp.now();
  const apptDate = appt.start;
  const isPast = now > apptDate;
  return past ? isPast : !isPast;
};

export const getTimezoneDifference = ({
  contact,
  group,
  contacts,
  startDate,
  endDate,
}: {
  contact?: ClientType;
  group?: GroupType | null;
  contacts?: ClientType[];
  startDate: Date;
  endDate?: Date;
}): null | { title: string; subtitle: string } => {
  const localStartTime = momentTimezone(startDate)
    .tz(moment.tz.guess())
    .format("h:mm A");

  let contactsWithDiffTime: ClientType[] = [];
  if (group && contacts) {
    const groupMemberIds = group?.members?.map((m) => m.clientId);
    contactsWithDiffTime = contacts
      .filter((c) => groupMemberIds?.includes(c.id))
      .filter((contact) => {
        if (!contact.timeZone) return false; // [1]

        const contactStartTime = momentTimezone(startDate)
          .tz(contact.timeZone)
          .format("h:mm A");

        return contactStartTime !== localStartTime;
      });
  } else if (contact?.timeZone) {
    const contactStartTime = momentTimezone(startDate)
      .tz(contact.timeZone)
      .format("h:mm A");

    if (contactStartTime !== localStartTime) {
      contactsWithDiffTime = [contact];
    }
  }

  const subtitle = `According to their client record ${pluralHelper(
    contactsWithDiffTime.length,
    "time zone",
    false
  )}`;

  if (contactsWithDiffTime.length > 1) {
    return {
      title: `Time difference for ${pluralHelper(
        contactsWithDiffTime.length,
        "client",
        true
      )}`,
      subtitle,
    };
  }

  if (contactsWithDiffTime.length === 1) {
    const contact = contactsWithDiffTime[0];
    const contactTimeZone = contact.timeZone as string; // We know that this is a string due to [1]

    const startTime = momentTimezone(startDate)
      .tz(contactTimeZone)
      .format("h:mm A");
    const endTime =
      endDate && momentTimezone(endDate).tz(contactTimeZone).format("h:mm A");
    const time = endTime ? `${startTime} - ${endTime}` : startTime;

    return {
      title: `${time} for ${displayNameFromContact(contact, true)}`,
      subtitle,
    };
  }

  return null;
};

// @TODO: remove it once all dates are normalized
export const getNormalizedDate = (date: any) => {
  if (typeof date === "string") {
    return new Date(date);
  }

  return date?.toDate ? date.toDate() : date;
};

export const isCancelRescheduleNoticeOverdue = (
  startDate: DateTime,
  compareDate: DateTime,
  minimumNoticeDuration: number
): boolean => {
  return (
    compareDate >
    startDate.minus({
      minutes: minimumNoticeDuration,
    })
  );
};

export const getLimitClientControlInfos = (
  appointment: AppointmentType,
  scheduler?: SchedulerType
): {
  canReschedule: boolean;
  canCancel: boolean;
  reschedulingOptions: any;
  allowedActions?: LimitClientControlAllowedActions;
  noticeInHours: number;
  noticeDate: DateTime;
  isNoticeOverdue: boolean;
  reasonRequired: boolean;
} => {
  const reschedulingOptions =
    scheduler?.reschedulingOptions ?? appointment.reschedulingOptions;

  const minimumNoticeDuration = reschedulingOptions?.minimumNotice.duration;
  const startDate =
    appointment.start &&
    DateTime.fromJSDate(new Date(appointment.start._seconds * 1000));
  const isNoticeOverdue =
    startDate &&
    minimumNoticeDuration &&
    isCancelRescheduleNoticeOverdue(
      startDate,
      DateTime.now(),
      minimumNoticeDuration
    );

  const allowedActions = reschedulingOptions?.allowedActions;
  const minimumNotice = reschedulingOptions?.minimumNotice;

  const noticeInHours =
    minimumNotice?.duration && minimumNotice.duration > 0
      ? Math.ceil(minimumNotice.duration / 60)
      : 0;
  const noticeDate = startDate.minus({
    hours: noticeInHours,
  });

  const isAppointmentCancelled = appointment.status === "cancelled";

  const canReschedule =
    [
      LimitClientControlAllowedActions.cancelAndReschedule,
      LimitClientControlAllowedActions.reschedule,
    ].includes(allowedActions) &&
    !isNoticeOverdue &&
    !!scheduler &&
    !isAppointmentCancelled;

  const canCancel =
    [
      LimitClientControlAllowedActions.cancelAndReschedule,
      LimitClientControlAllowedActions.cancel,
    ].includes(allowedActions) &&
    !isNoticeOverdue &&
    !appointment.paymentId &&
    !isAppointmentCancelled;

  return {
    canReschedule,
    canCancel,
    reschedulingOptions,
    allowedActions,
    noticeInHours,
    noticeDate,
    isNoticeOverdue: !!isNoticeOverdue,
    reasonRequired: reschedulingOptions?.reasonRequired === true,
  };
};

export const isAppointmentTodayOrPast = (
  appointment: AppointmentType
): boolean => {
  const appointmentEndDate = appointment.end as any;
  const endDate = appointmentEndDate.seconds
    ? moment.unix(appointmentEndDate.seconds)
    : moment(appointmentEndDate);
  const tomorrow = moment().add(1, "day").startOf("day");
  const hasAppointmentEnded = endDate.startOf("day").isBefore(tomorrow);
  return hasAppointmentEnded;
};

export const isAppointmentDeclined = (
  appointment: AppointmentType
): boolean => {
  const attendees = appointment?.gcal?.event?.attendees ?? [];

  return attendees
    .filter((attendee: any) => attendee.organizer !== true)
    .every((attendee: any) => attendee.responseStatus === "declined");
};

export const decoratedAppointmentStatus = (appointment: AppointmentType) => {
  const shouldOverrideStatus =
    isAppointmentDeclined(appointment) && appointment.status === "confirmed";

  return shouldOverrideStatus ? "declined" : appointment.status;
};
