export enum LimitClientControlAllowedActions {
  cancelAndReschedule = "cancel_reschedule",
  reschedule = "reschedule",
  cancel = "cancel",
  none = "none",
}

export interface AllowedActionKeysAndValues {
  value: LimitClientControlAllowedActions;
  label: string;
}

export const schedulerAllowedActionKeysAndValues: AllowedActionKeysAndValues[] =
  [
    {
      value: LimitClientControlAllowedActions.cancelAndReschedule,
      label: "Cancel & Reschedule",
    },
    { value: LimitClientControlAllowedActions.reschedule, label: "Reschedule" },
    { value: LimitClientControlAllowedActions.cancel, label: "Cancel" },
    {
      value: LimitClientControlAllowedActions.none,
      label: "Neither cancel nor reschedule",
    },
  ];

export const appointmentAllowedActionKeysAndValues: AllowedActionKeysAndValues[] =
  [
    { value: LimitClientControlAllowedActions.cancel, label: "Cancel" },
    { value: LimitClientControlAllowedActions.none, label: "Not cancel" },
  ];
