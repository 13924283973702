import { PackageSchedulerType } from "api-services/definitions/package-instances";
import { DateTime } from "luxon";
import moment from "moment";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import {
  PackageContentType,
  PackageTimeType,
} from "@lib/data/schemas/packages";
import { PackageInstanceCycleHelper } from "@lib/models/package-instances/helpers/package-instance-cycle-helper";
import { isCycleBased } from "@lib/models/package-instances/utils";
import { getUsageBasedPackageInstanceCycleDates } from "@lib/packages/package-instances";
import pluralHelper from "@lib/utils/pluralHelper";

import {
  getFrequency,
  getTotalSessionsInMinutes,
} from "@components/Package/utils";

/**
 * Formats the reset date
 * */
export const formatResetDate = (resetDate: Date) =>
  moment(
    // @TODO: remove this conditional once the dates are normalized
    resetDate?.toDate ? resetDate.toDate() : resetDate
  ).format("MMM DD");

/**
 * Get appointments from a package instance and schedulers
 * */
const getAppointmentsFromPackageInstance = (
  packageInstance: PackageInstanceType,
  schedulers: PackageSchedulerType[],
  currentCycleOnly: boolean = false,
  fromCycle?: number,
  relative: boolean = false
) => {
  const appointments = schedulers
    ?.map((scheduler) => scheduler.appointments || [])
    .flat() as AppointmentType[];

  if (
    !isCycleBased(packageInstance?.packageType) ||
    ((!currentCycleOnly || !packageInstance.currentCycle) && !fromCycle)
  )
    return appointments;

  const isUsageBased = packageInstance.packageType === "usage";

  const getUsageBasedCycleNumber = (fromDate: Date) => {
    const { cycle = fromCycle } =
      getUsageBasedPackageInstanceCycleDates(
        packageInstance,
        fromDate,
        fromCycle
      ) ?? {};
    return cycle;
  };

  const filteredByCycle = appointments.filter((appt) => {
    if (!appt.packageInstanceCycle) return true;
    if (fromCycle) {
      if (relative) {
        return appt.packageInstanceCycle <= fromCycle;
      } else {
        if (isUsageBased) {
          const cycle = getUsageBasedCycleNumber(getNormalizedDate(appt.start));
          return cycle === fromCycle;
        }

        return appt.packageInstanceCycle == fromCycle;
      }
    }

    if (isUsageBased) {
      return (
        getUsageBasedCycleNumber(getNormalizedDate(appt.start)) ===
        getUsageBasedCycleNumber(new Date())
      );
    }

    return appt.packageInstanceCycle === packageInstance.currentCycle;
  });
  return filteredByCycle;
};

export const getRecurringCycleTotal = (
  pi?: PackageInstanceType,
  cycle?: number | null
) => {
  return (
    (pi?.frequency?.total || 0) +
    (pi?.cycleExtensions?.[cycle || pi.currentCycle!] || 0)
  );
};

const getTotalSessions = (
  pi?: PackageInstanceType,
  currentCycleOnly?: boolean
) => {
  // Return total sessions and only calculate if current cycle only
  if (!currentCycleOnly && pi?.totalSessions) return pi.totalSessions;

  if (!pi?.frequency) return pi?.totalSessions || 0;
  if (pi?.rollOver) pi.totalSessions || 0;
  if (currentCycleOnly) return getRecurringCycleTotal(pi, pi?.currentCycle);
  let total = 0;
  for (let i = 1; i <= pi.currentCycle!; i++) {
    total += getRecurringCycleTotal(pi, i);
  }
  return total;
};

/**
 * Get the total of appointment remaining given a package instance
 * */
export const getRemainingAppointmentsAcrossSchedulers = (
  schedulers: PackageSchedulerType[],
  packageInstance: PackageInstanceType,
  currentCycleOnly?: boolean
): number => {
  const totalSessions = getTotalSessions(packageInstance, currentCycleOnly);

  const appointments = getAppointmentsFromPackageInstance(
    packageInstance,
    schedulers,
    currentCycleOnly
  );

  const completed = appointments?.length || 0;
  const remaining = totalSessions - completed;
  return Math.max(0, remaining);
};

/**
 * Checks if a package instance is complete
 * */
type IsPackageInstanceCompleteType = (args: {
  packageInstance: PackageInstanceType;
  totalRemainingAcrossSchedulers: number;
  totalSessionsFromSchedulersQuantity: number;
  totalSessionsConsumedInTotalAppointments: number;
  totalAppointmentsRemainingInMinutes: number;
  shouldAutocomplete: boolean;
}) => boolean;
const isPackageInstanceComplete: IsPackageInstanceCompleteType = ({
  packageInstance,
  totalRemainingAcrossSchedulers,
  totalSessionsFromSchedulersQuantity,
  totalSessionsConsumedInTotalAppointments,
  totalAppointmentsRemainingInMinutes,
  shouldAutocomplete,
}) => {
  const distributeSessions = !!packageInstance?.distributeSessions;
  const { contentType = "sessions" } = packageInstance;
  const isContentTypeSession = contentType === "sessions";

  if (packageInstance.status === "completed") return true;
  if (packageInstance.packageType === "usage") return false;

  if (!isContentTypeSession) {
    return totalAppointmentsRemainingInMinutes === 0 || shouldAutocomplete;
  }

  if (distributeSessions) {
    return totalRemainingAcrossSchedulers === 0;
  } else {
    return (
      totalSessionsConsumedInTotalAppointments ===
      totalSessionsFromSchedulersQuantity
    );
  }
};

/**
 * Returns some helper data from a package instance
 * */
type GetPackageInstanceHelper = (
  packageInstance: PackageInstanceType,
  currentCycleOnly?: boolean,
  fromCycle?: number
) => {
  autocomplete: boolean;
  contentType: PackageContentType;
  packageType: string;
  totalSessions: number;
  distributeSessions: boolean;
  timeType: PackageTimeType | null;
  isContentTypeSession: boolean;
  isContentTypeTime: boolean;
  isPackageTypeRecurring: boolean;
  isUsagePackage: boolean;
  isRollOver: boolean;
  isPackageLocked: boolean;
};

export const getPackageInstanceHelper: GetPackageInstanceHelper = (
  packageInstance: PackageInstanceType | undefined,
  currentCycleOnly?: boolean
) => {
  const {
    contentType = "sessions",
    autocomplete = true,
    packageType = "one-time",
    timeType = "hours",
  } = packageInstance ?? {};

  const isContentTypeSession = contentType === "sessions";
  const isContentTypeTime = contentType === "time";
  const isPackageTypeRecurring = packageType === "recurring";
  const isUsagePackage = packageType === "usage";
  const isRollOver = packageInstance?.rollOver || false;
  const isPackageLocked = packageInstance?.locked ?? false;

  const totalSessions =
    (isPackageTypeRecurring
      ? getFrequency(packageInstance!, currentCycleOnly)
      : packageInstance?.totalSessions) ?? 0;

  const distributeSessions = isPackageTypeRecurring
    ? true
    : !!packageInstance?.distributeSessions;

  return {
    autocomplete,
    contentType,
    packageType,
    totalSessions,
    distributeSessions,
    timeType,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isRollOver,
    isPackageLocked,
  };
};

/**
 * Returns data to identify wheter the package instance is from distribute
 * across schedulers
 * */
type GetPackageInstanceInfo = (
  packageInstance: PackageInstanceType,
  schedulers: PackageSchedulerType[],
  currentCycleOnly?: boolean,
  fromCycle?: number
) => {
  distributeSessions: boolean;
  totalSessions: number;
  contentType: PackageContentType;
  totalRemainingAcrossSchedulers: number;
  totalAppointmentsRemainingInMinutes: number;
  totalAppointmentsConsumedInMinutes: number;
  totalSessionsConsumedInTotalAppointments: number;
  totalSessionsInMinutes: number;
  isTotalRemainingAcrossSchedulersComplete: boolean;
  isContentTypeSession: boolean;
  isContentTypeTime: boolean;
  isPackageTypeRecurring: boolean;
  isUsagePackage: boolean;
  isRollOver: boolean;
  showSchedulerRemaining: boolean;
  formattedTimeConsumed: string;
  formattedRemaining: string;
  autocomplete: boolean;
  isCompleted: boolean;
  isPackageLocked: boolean;
};

export const getPackageInstancePausedOnDate = (
  packageInstance: PackageInstanceType
) => {
  const packageInstanceCycleHelper = new PackageInstanceCycleHelper(
    packageInstance,
    [],
    []
  );
  return packageInstanceCycleHelper.getPackageInstancePausedOnDate();
};

export const isPackageInstancePaused = (
  packageInstance: PackageInstanceType
) => {
  return !!getPackageInstancePausedOnDate(packageInstance);
};

export const getPackageInstanceInfo: GetPackageInstanceInfo = (
  packageInstance,
  schedulers,
  currentCycleOnly = false,
  fromCycle
) => {
  const {
    contentType,
    autocomplete,
    totalSessions,
    distributeSessions,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isRollOver,
    isPackageLocked,
  } = getPackageInstanceHelper(packageInstance, currentCycleOnly);

  const totalRemainingAcrossSchedulers =
    getRemainingAppointmentsAcrossSchedulers(
      schedulers,
      packageInstance,
      isPackageTypeRecurring && !isRollOver ? currentCycleOnly : false
    );

  const showSchedulerRemaining =
    !distributeSessions && isContentTypeSession && !isUsagePackage;

  const totalSessionsInMinutes = getTotalSessionsInMinutes(
    totalSessions,
    packageInstance?.timeType || "hours"
  );
  const appointments = getAppointmentsFromPackageInstance(
    packageInstance,
    schedulers,
    !isRollOver && !fromCycle && currentCycleOnly,
    fromCycle,
    isRollOver
  );

  const totalAppointmentsConsumedInMinutes =
    getTotalAppointmentsConsumed(appointments);

  const totalAppointmentsRemainingInMinutes = Math.round(
    totalSessionsInMinutes - totalAppointmentsConsumedInMinutes
  );

  const isTimeMinutesBased =
    isContentTypeTime && packageInstance?.timeType === "minutes";

  const schedulerDurations = schedulers.map((scheduler) => scheduler.duration);
  const areAllSchedulersUnavailable = schedulerDurations.every(
    (duration = 0) => duration > totalAppointmentsRemainingInMinutes
  );

  const totalSessionsFromSchedulersQuantity = packageInstance?.items?.reduce(
    (acc, scheduler) => {
      const { quantity = 0 } = scheduler;
      return acc + quantity;
    },
    0
  );

  const totalSessionsConsumedInTotalAppointments = appointments.length;

  const isTotalRemainingAcrossSchedulersComplete = isContentTypeSession
    ? totalRemainingAcrossSchedulers === 0
    : totalAppointmentsRemainingInMinutes === 0;

  const shouldAutocomplete =
    (isContentTypeTime || isPackageTypeRecurring) &&
    areAllSchedulersUnavailable &&
    autocomplete &&
    !currentCycleOnly;

  const isCompleted = isPackageInstanceComplete({
    packageInstance,
    totalRemainingAcrossSchedulers,
    totalSessionsFromSchedulersQuantity,
    totalSessionsConsumedInTotalAppointments,
    totalAppointmentsRemainingInMinutes,
    shouldAutocomplete,
  });

  // formatters
  const formattedTimeConsumed =
    isTimeMinutesBased || totalAppointmentsConsumedInMinutes < 60
      ? formatInMinutes(totalAppointmentsConsumedInMinutes)
      : formatDuration(totalAppointmentsConsumedInMinutes);

  const formattedTimeRemaining =
    isTimeMinutesBased || totalAppointmentsRemainingInMinutes < 60
      ? formatInMinutes(totalAppointmentsRemainingInMinutes)
      : formatDuration(totalAppointmentsRemainingInMinutes);

  const formattedRemining = isContentTypeSession
    ? pluralHelper(totalRemainingAcrossSchedulers, "appointment")
    : formattedTimeRemaining;

  return {
    distributeSessions,
    totalSessions: distributeSessions
      ? totalSessions
      : totalSessionsFromSchedulersQuantity,
    contentType,
    autocomplete: shouldAutocomplete,
    totalRemainingAcrossSchedulers,
    totalAppointmentsRemainingInMinutes,
    totalAppointmentsConsumedInMinutes,
    totalSessionsConsumedInTotalAppointments,
    totalSessionsInMinutes,
    isTotalRemainingAcrossSchedulersComplete,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isCompleted,
    isRollOver,
    showSchedulerRemaining,
    formattedTimeConsumed: formattedTimeConsumed || "0",
    formattedRemaining: formattedRemining,
    isPackageLocked,
  };
};

/**
 * Get the total appointments consumed in minutes or seconds
 * */
export const getTotalAppointmentsConsumed = (
  appointments: AppointmentType[],
  format: "minutes" | "seconds" = "minutes"
) => {
  const getDateTime = (date: any) =>
    date?.seconds ? DateTime.fromSeconds(date.seconds) : DateTime.fromISO(date);

  const result = appointments?.reduce((acc, item) => {
    const { start, end } = item;
    const startDate = getDateTime(start);
    const endDate = getDateTime(end);
    const diff = endDate.diff(startDate, format)[format];
    return acc + diff;
  }, 0);

  return result;
};

/**
 * Formats a duration in minutes to a human readable format
 * */
export const formatDuration = (value: number) => {
  const hours = value / 60;
  const calculatedStringValue = hours.toString();
  const floatedHours = parseFloat(calculatedStringValue).toFixed(2);
  const splitted = floatedHours.split(".");
  let formattedFloatHours;

  if (splitted[1] === "0") {
    formattedFloatHours = splitted[0];
  } else {
    formattedFloatHours = parseFloat(floatedHours);
  }

  let formattedDuration = "";

  if (hours > 0) {
    formattedDuration += `${formattedFloatHours} hours`;
    if (hours === 1) {
      formattedDuration = formattedDuration.replace("hours", "hour");
    }
  }

  return formattedDuration;
};

/**
 * Formats a duration in minutes to a human readable format
 * */
export const formatInMinutes = (value: number) => `${value} minutes`;
