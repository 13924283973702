import { DateTime } from "luxon";

import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { SchedulerType } from "@lib/data/schemas/scheduler";

export class PackageInstanceCycleHelper {
  constructor(
    private readonly packageInstance: PackageInstanceType,
    private readonly currentCycleAppointments: AppointmentType[],
    private readonly schedulers: SchedulerType[]
  ) {}

  get totalTimeInMinutes(): number | undefined {
    const isMinutes = this.packageInstance.timeType === "minutes";
    if (this.packageInstance.frequency && !this.packageInstance.rollOver) {
      if (isMinutes) {
        return this.packageInstance.frequency.total;
      }
      return this.packageInstance.frequency.total * 60;
    }

    if (!this.packageInstance.totalSessions) return undefined;

    if (isMinutes) {
      return this.packageInstance.totalSessions;
    }
    return this.packageInstance.totalSessions * 60;
  }

  get appointmentsDurationInMinutes(): number {
    return this.currentCycleAppointments.reduce((acc, item) => {
      const { start, end } = item;

      const startDate = DateTime.fromJSDate(start);
      const endDate = DateTime.fromJSDate(end);
      const diff = endDate.diff(startDate, "minutes")["minutes"];
      return acc + diff;
    }, 0);
  }

  get remainingTimeInMinutes(): number | undefined {
    if (!this.totalTimeInMinutes) return undefined;
    if (this.appointmentsDurationInMinutes > this.totalTimeInMinutes) return 0;
    return Math.round(
      this.totalTimeInMinutes - this.appointmentsDurationInMinutes
    );
  }

  get totalAppointmentsConsumed(): number {
    return this.currentCycleAppointments.length;
  }

  get totalSessions(): number {
    if (this.packageInstance.distributeSessions) {
      return this.packageInstance.totalSessions ?? 0;
    }
    return this.packageInstance.items.reduce((acc, { quantity = 0 }) => {
      return acc + quantity;
    }, 0);
  }

  get remainingSessions(): number {
    const remaining = this.totalSessions - this.totalAppointmentsConsumed;
    return Math.max(0, remaining);
  }

  get contentType(): "sessions" | "time" {
    return this.packageInstance.contentType ?? "sessions";
  }

  get isRecurring(): boolean {
    return this.packageInstance.packageType === "recurring";
  }

  areAllSchedulersUnavailable(): boolean {
    const schedulerDurations = this.schedulers.map(
      (scheduler) => scheduler.duration
    );
    return schedulerDurations.every(
      (duration = 0) => duration > (this.remainingTimeInMinutes ?? 0)
    );
  }

  shouldAutoComplete(): boolean {
    return (
      (this.isRecurring || this.contentType === "time") &&
      this.areAllSchedulersUnavailable() &&
      !!this.packageInstance.autocomplete
    );
  }

  shouldBlockCycle(): boolean {
    if (this.packageInstance.packageType === "usage") return false;
    if (this.packageInstance.status === "completed") return false;
    if (!this.isRecurring) return false;

    if (this.contentType === "time") {
      return this.remainingTimeInMinutes === 0 || this.shouldAutoComplete();
    }
    return this.remainingSessions === 0;
  }

  getPackageInstancePausedOnDate(): Date | null {
    const packageInstance = this.packageInstance;
    const currentCyclePause =
      packageInstance.cyclePauses?.[packageInstance.currentCycle!];

    const lastCyclePause = currentCyclePause?.at(-1);

    const isPaused =
      lastCyclePause &&
      ["active", "blocked"].includes(packageInstance.status) &&
      !lastCyclePause.endDate;

    const pausedOn = isPaused ? lastCyclePause.startDate : null;

    return pausedOn;
  }

  getUnusedCreditsForRollover(): number {
    if (this.contentType === "time") {
      return this.remainingTimeInMinutes ?? 0;
    }
    return this.remainingSessions;
  }

  isPaused(): boolean {
    return !!this.getPackageInstancePausedOnDate();
  }

  appointmentExceedsTotal(appointmentDurationInMinutes: number): boolean {
    if (this.contentType === "time") {
      return appointmentDurationInMinutes > this.remainingTimeInMinutes!;
    }
    if (this.contentType === "sessions") {
      return this.remainingSessions === 0;
    }
    return false;
  }
}
