import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const InfoFilledIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={classNames("fill-current", className)}
    >
      <path
        fillRule="evenodd"
        d="M12 20a8 8 0 100-16 8 8 0 000 16zm-1-9.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-6zm.5-3.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default InfoFilledIcon;
